import React from 'react';
import {Route, Routes} from 'react-router-dom';
import View from './Pages/View/View';
import './App.scss';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import AboutUs from "./Pages/AboutUs/AboutUs";
import Feedback from "./Pages/Feedback/Feedback";
import MigrationGuide from "./Pages/MigrationGuide/MigrationGuide";
import HedgeCheck from "./Pages/HedgeCheck/HedgeCheck";
import Genres from "./Pages/Genres/Genres";

function App() {

    return (
        <Routes>
            <Route exact path="hedgecheck" element={<HedgeCheck/>}>
                <Route path=":lang" element={<HedgeCheck/>}/>
            </Route>
            <Route exact path="feedback" element={<Feedback/>}>
                <Route path=":lang" element={<Feedback/>}/>
            </Route>
            <Route exact path="about-us" element={<AboutUs/>}>
                <Route path=":lang" element={<AboutUs/>}/>
            </Route>
            <Route exact path="privacyPolicy" element={<PrivacyPolicy/>}>
                <Route path=":product/:lang" element={<PrivacyPolicy/>}/>
            </Route>
            <Route exact path="migrationGuide" element={<MigrationGuide/>}>
                <Route path=":lang" element={<MigrationGuide/>}/>
            </Route>
            <Route exact path="genres" element={<Genres/>}>
                <Route path=":lang/:new_genre" element={<Genres/>}/>
            </Route>
            <Route exact path="*" element={<View/>}>
                <Route path=":product/:lang" element={<View/>}/>
            </Route>
        </Routes>
    );
}

export default App;
